/** @type {import('tailwindcss').Config} */

const plugin = require("tailwindcss/plugin")

module.exports = {
  content: ["./src/**/*.{js,jsx,html,ts,tsx}"],
  theme: {
    extend: {
      screens: {
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        "2xl": "1440px",
        "3xl": "1720px",
      },
      container: {
        padding: {
          DEFAULT: "1rem",
          sm: "0.875rem",
          md: "2rem",
          lg: "2rem",
          xl: "2.625rem",
          "2xl": "3.25rem",
          "3xl": "4rem",
        },
        center: true,
        grid: {
          sm: {
            size: "40px", // 2.5rem
            gap: "0.75rem",
          },
          md: {
            size: "44px", // 2.75rem
            gap: "1rem",
          },
          lg: {
            size: "58px", // 3.625rem
            gap: "1.5rem",
          },
          xl: {
            size: "74px", // 4.625rem
            gap: "1.75rem",
          },
          "2xl": {
            size: "82px", // 5.125rem
            gap: "2rem",
          },
          "3xl": {
            size: "96px", // 6rem
            gap: "2.5rem",
          },
        },
      },

      colors: {
        white: {
          name: "white",
          DEFAULT: "#FFFFFF",
        },
        black: {
          name: "black",
          DEFAULT: "#000000",
        },
        grey: {
          name: "grey",
          50: "#FFFFFF",
          100: "#F4F4F5",
          200: "#E4E4E7",
          300: "#CECED2",
          400: "#ABAFB3",
          500: "#7F8285",
          DEFAULT: "#7F8285",
          600: "#5B6064",
          700: "#40454A",
          800: "#252729",
          900: "#1E1E1F",
        },
        berry: {
          name: "berry",
          50: "#FFECF2",
          100: "#FFBBC8",
          200: "#FF99AE",
          300: "#FF7895",
          400: "#FF5076",
          500: "#E90C4E",
          DEFAULT: "#E90C4E",
          600: "#C40C44",
          700: "#930933",
          800: "#640522",
          900: "#400315",
        },
      },

      textColor: {
        primary: "#3490dc",
        secondary: "#ffed4a",
        danger: "#e3342f",

        "black-100": "#CECECE",
        // 'black-200': '',
        // 'black-300': '',
        // 'black-400': '',
        "black-500": "#6E6E6E",
        // 'black-600': '',
        // 'black-700': '',
        "black-800": "#464646",
        "black-900": "#2F2F2F",
        "black-1000": "#000000",
      },

      fonts: {
        sans: [
          "Encode Sans",
          "system-ui",
          "-apple-system",
          "Segoe UI",
          "Roboto",
          "Ubuntu",
          "Cantarell",
          "Noto Sans",
          "sans-serif",
          "Ubuntu",
          "Cantarell",
          "Noto Sans",
          "sans-serif",
        ],
        serif: ["Encode Sans", "system-ui", "-apple-system", "serif"],
      },

      fontSize: {
        "3xs": ["0.5rem", "1.4"], // 8x
        "2xs": ["0.625rem", "1.4"], // 10x
        xs: [".75rem", "1.4"], // 12px
        sm: [".875rem", "1.2"], // 14px
        base: ["1rem", "1.6"], // 16px
        lg: ["1.125rem", "1.6"], // 18px
        xl: ["1.25rem", "1.6"], // 20px
        "2xl": ["1.375rem", "1.6"], // 22px
        "3xl": ["1.5rem", "1.2"], // 24px
        "4xl": ["1.625rem", "1.2"], // 26px
        "5xl": ["2rem", "1.2"], // 32px
        "6xl": ["2.5rem", "1.2"], // 40px
        "7xl": ["3rem", "1.2"], // 48px
        "8xl": ["3.5rem", "1.2"], // 56px
        "9xl": ["4rem", "1.2"], // 64px
        "10xl": ["4.5rem", "1.2"], // 72px
      },

      margin: {
        18: "4.5rem",
      },
      borderRadius: {
        "3xl": "1.25rem", //20px
        "4xl": "2.5rem", //40px
        "5xl": "3.75rem", //60px
        "6xl": "5rem", //80px
      },
      transitionTimingFunction: {
        "out-back": "cubic-bezier(0.34, 1.56, 0.64, 1)",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("@tailwindcss/forms"),

    plugin(function gridRasters({ addComponents, theme }) {
      const rasterSizes = ["12", "10", "8"]
      const grid = theme("container.grid")
      const containerSizes = Object.keys(grid)

      const rasters = rasterSizes.reduce((rasters, rasterSize, i) => {
        rasters[`.raster-${rasterSize}`] = containerSizes.reduce(
          (css, containerSize) => {
            const { size, gap } = grid[containerSize]
            css[`@screen ${containerSize}`] = {
              paddingLeft: `calc((${size} + ${gap}) * ${i} )`,
              paddingRight: `calc((${size} + ${gap}) * ${i} )`,
            }
            return css
          },
          {}
        )

        return rasters
      }, {})

      addComponents(rasters)
    }),

    plugin(function gridGaps({ addComponents, theme }) {
      const grid = theme("container.grid")
      const gridSizes = Object.entries(grid)

      const gaps = gridSizes.reduce((css, [key, { gap }]) => {
        css[`@screen ${key}`] = {
          gap,
        }
        return css
      }, {})

      addComponents({
        ".grid-gap": {
          gap: gridSizes[0][1].gap,
          ...gaps,
        },
      })
    }),

    plugin(function imgRadius({ addComponents, theme }) {
      const radius = theme("borderRadius")

      addComponents({
        ".img-radius": {
          borderRadius: radius["3xl"],
          overflow: "hidden",
          "@screen md": {
            borderRadius: radius["4xl"],
          },
        },
      })
    }),

    plugin(function hyphens({ addComponents, theme }) {
      addComponents({
        ".hyphens": {
          "-webkit-hyphens": "auto",
          "-ms-hyphens": "auto",
          hyphens: "auto",
        },
      })
    }),

    /**
     * Füge basis permutation Klassen hinzu für alle voll color Stärke 700
     * .permutation-${letter}-${colorName}
     */
    plugin(function ({ addBase, theme }) {
      const themeColors = theme("colors")
      const letters = ["v", "o", "l"]

      const css = Object.entries(themeColors)
        .filter(([key, value]) => {
          return key.startsWith("voll") && !key.startsWith("voll-")
        })
        .map(([name, colors]) => {
          return [name.substr(4), colors["700"]]
        })
        .reduce((css, [colorName, color]) => {
          letters.forEach((letter) => {
            css[`.permutation-${letter}-${colorName}`] = {
              backgroundImage: `url(/permutationen/permutation_${letter}_${colorName}.svg)`,
              backgroundRepeat: "repeat",
              "--permutation-size": "10rem",
              backgroundSize: "var(--permutation-size)",
            }
          })

          return css
        }, {})

      addBase(css)
    }),
  ],
}
